/* Footer css
============================================================================================ */
.footer_area{
	background: #1b1b1b;
	padding-left: 76px;
	padding-right: 76px;
	padding-top: 120px;
	.footer_widgets{
		padding-bottom: 100px;
		.f_widgets_item{
			flex: 0 0 20%;
			max-width: 20%;
			&:nth-child(2){
				.f_contact{
					margin-left: -56px;
				}
			}
			&:nth-child(3){
				.f_link{
					margin-left: 75px;
				}
			}
			&:nth-child(4){
				.f_link{
					margin-left: 95px;
				}
			}
			&:nth-child(5){
				.f_link{
					margin-left: 85px;
				}
			}
		}
	}
}
.f_widgets_item{
	
}
.f_title{
	margin-bottom: 25px;
	h3{
		color: #fff;
		font-family: $hee;
		font-weight: bold;
		font-size: 18px;
		text-transform: uppercase;
		margin-bottom: 0px;
	}
}
.f_contact{
	max-width: 270px;
	a{
		display: block;
		font-size: 14px;
		line-height: 24px;
		color: $pfont;
		font-family: $hee;
		font-weight: normal;
		margin-bottom: 15px;
		&:last-child{
			margin-bottom: 0px;
		}
	}
	p{
		font-size: 14px;
		line-height: 24px;
		color: $pfont;
		font-family: $hee;
		font-weight: normal;
		margin-bottom: 15px;
	}
}
.f_link{
	.list{
		li{
			margin-bottom: 10px;
			a{
				font-size: 14px;
				color: $pfont;
				font-family: $hee;
				font-weight: normal;
				@include transition;
			}
			&:last-child{
				margin-bottom: 0px;
			}
			&:hover{
				a{
					color: #fff;
				}
			}
		}
	}
}


.footer_copyright{
	overflow: hidden;
	border-top: 2px solid #282828;
	display: flex;
	padding: 48px 0px;
	.left_text{
		display: inline-flex;
		width: 50%;
		flex-direction: row;
		p{
			font-size: 14px;
			line-height: 24px;
			color: $pfont;
			font-family: $hee;
			font-weight: normal;
			margin-bottom: 0px;
			a{
				color: #fff;
				font-weight: bold;
			}
		}
	}
	.middle_text{
		display: inline-flex;
    	float: none;
		max-width: 500px;
		width: 100%;
		text-align: center;
		.list{
			display: inline-block;
			width: 100%;
			text-align: center;
			li{
				display: inline-block;
				margin-right: 25px;
				a{
					font-size: 14px;
					line-height: 24px;
					color: $pfont;
					font-family: $hee;
					font-weight: normal;
					@include transition;
				}
				&:last-child{
					margin-right: 0px;
				}
				&:hover{
					a{
						color: #fff;
					}
				}
			}
		}
	}
	.right_text{
		display: inline-flex;
		width: 50%;
		flex-direction: row-reverse;
		.list{
			li{
				display: inline-block;
				margin-right: 25px;
				a{
					font-size: 14px;
					line-height: 24px;
					color: $pfont;
					font-family: $hee;
					font-weight: normal;
					@include transition;
				}
				&:hover, &.active{
					a{
						color: #fff;
					}
				}
				&:last-child{
					margin-right: 0px;
				}
			}
		}
	}
}



.white_f{
	background: #fff;
	.f_title{
		h3{
			color: $dip;
		}
	}
	.f_contact{
		a{
			color: $gray;
		}
		p{
			color: $gray;
		}
	}
	.f_link{
		.list{
			li{
				a{
					color: $gray;
				}
				&:hover{
					a{
						color: $dip;
					}
				}
			}
		}
	}
	.footer_copyright{
		border-top: 2px solid #f0f0f0;
		.left_text{
			p{
				color: $gray;
				a{
					color: $dip;
				}
			}
		}
		.middle_text{
			.list{
				li{
					a{
						color: $gray;
					}
					&:hover{
						a{
							color: $dip;
						}
					}
				}
			}
		}
		.right_text{
			.list{
				li{
					a{
						color: $gray;
					}
					&:hover, &.active{
						a{
							color: $dip;
						}
					}
				}
			}
		}
	}
}

/* End Footer css
============================================================================================ */