.list{
    list-style: none;
    margin: 0px;
    padding: 0px;
}

a{
    text-decoration:none;
    &:hover, &:focus{
       text-decoration:none;
       outline: none;
    }
}
.row.m0{
    margin: 0px;
}
.p0{
    padding: 0px;   
}
body{
    font-family: $hee; 
    color: $pfont;
	font-weight: normal;
	font-size: 14px;
}
body{
    margin: 0px;
    padding: 0px;
}
#success{
    display: none;
}
#error{
    display: none;
}

.display_table{
    display: table;
    width: 100%;
}
.display_table_row{
    display: table-row;
}
.display_table_cell{
    display: table-cell;
    float: none !important;
}
.p_100{
    padding: 100px 0px;
}
iframe{
    border: none;
}
i{
    &:before{
        margin-left: 0px !important;
    }
}

.per_bg{
    background: #0e0e0e;
}

.row_direction{
	flex-direction: row-reverse;
}


::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: $baseColor;
}

::selection{
  background-color:$baseColor;
  color:#fff;
}

-webkit-::selection{
    background-color:$baseColor;
    color:#fff;
}

::-moz-selection{
    background-color:$baseColor;
    color:#fff;
}


/* Container Custome Code css
============================================================================================ */
@media (min-width: 1200px){
    .container {
        max-width: 1200px; 
    }
}
/* End Container Custome Code css
============================================================================================ */
.s_title{
	color: $pfont;
	font-weight: bold;
	font-size: 18px;
	margin-bottom: 45px; 
	&.white{
		color: $gray;
	}
}
.main_title{
	h2{
		color: #fff; 
		font-family: $hee;
		font-size: 42px;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 0px;
	}
	&.white{
		h2{
			color: $dip;
		}
	}
}
/* Main title css
============================================================================================ */





/* Proloader css
============================================================================================ */
.preloader{
  position: fixed;
  overflow: hidden;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background:#fff;
  color:#4b4b4b;
  text-align: center;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #10fff4;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}



/* Icon Push */
@-webkit-keyframes hvr-icon-push {
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes hvr-icon-push {
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
.hvr-icon-push {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-push .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-push:hover .hvr-icon, .hvr-icon-push:focus .hvr-icon, .hvr-icon-push:active .hvr-icon {
  -webkit-animation-name: hvr-icon-push;
  animation-name: hvr-icon-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}



