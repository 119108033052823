$os: 'Oswald', sans-serif;
$hee: 'Heebo', sans-serif;

$baseColor: #10fff4;
$dip: #263a4f;
$gray: #8d9aa8;
$pfont: #999999;

@mixin transition($property: all, $duration: 200ms, $animate: linear, $delay:0s){
    transition: $property $duration $animate $delay; 
}

@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

.col-md-offset-right-1,.col-lg-offset-right-1{
    margin-right: 8.33333333%;
}

@import url('https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,900|Oswald:300,400,500,600,700');


