.success-message, .error-message {
    display: none;
    margin-top: 5px;
	padding-left: 15px; 
}

label{
	margin-top: 8px;
}

.right_contact_form .form-group {
    textarea, input {
        background: rgba(0,0,0,.1) !important;
        padding: 15px !important;
    }
}

/* End Blog Comment Form css
============================================================================================ */