/* Inter Studio css
============================================================================================ */
.bg_color{
	background: #1b1b1b;
}

/* Specialliazition css
============================================================================================ */
.speciallization_area{
	padding-top: 130px;
	.s_title{
		margin-bottom: 70px;
	}
}
.spec_item{
	img{}
	h4{
		font-size: 30px;
		color: #fff;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 15px;
		margin-top: 40px;
	}
	p{
		font-size: 14px;
		line-height: 24px;
		color: $pfont;
		letter-spacing: .21px;
		font-weight: $hee;
		font-weight: normal;
		max-width: 320px;
		margin-bottom: 30px;
	}
}

.white_cl{
	.spec_item{
		h4{
			color: $dip;
		}
		p{
			color: $gray;
		}
	}
}

/* End Specialliazition css
============================================================================================ */