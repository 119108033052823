.animations{
	position: relative;
}
.full_pad{
	overflow: hidden;
	.animsition {
		position: absolute;
		z-index: 35;
		left: 0;
		width: 100%;
		top: 0;
		height: 100%;
		background: #fff;
	}
}


@media(min-width: 1800px){
	.section{
		overflow-x: hidden;
	}
	.box_lay{
		margin-left: 40px;
		margin-right: 40px;
		overflow: hidden;
		position: relative;
		min-height: calc(100vh - 80px);
		margin-top: 40px;
	}
}

.pagepiling{
	.pp-scrollable{
		
	}
	.scroll-wrap{
		position: relative;
		min-height: 100%;
	}
	.section-bg{
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		z-index: -1;
	}
	.mapBox{
		position: absolute !important;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100% !important;
		background-size: cover;
		background-position: center;
		button{
			display: none;
		}
		.gmnoprint.gm-bundled-control{
			display: none;
		}
	}
}
.section{
	background: #1b1b1b;
	&.slider{
		.forcefullwidth_wrapper_tp_banner{
			
		}
	}
    &.section-2{
        .scrollable-content{
            .vertical-centred{
                padding-top: 8rem;
                padding-bottom: 8rem;
            }
        }
    }
}
.scrollable-content{
	white-space: nowrap;
    width: 100%;
	font-size: 0;
	&:before{
		content: '';
		display: inline-block;
		height: 100vh;
		vertical-align: middle;
		@media(min-width: 1800px){
			height: calc(100vh - 80px);
		}
	}
	.vertical-centred{
		width: 100%;
		height: 100%;
		display: inline-block;
		vertical-align: middle;
		white-space: normal;
		position: relative;
		padding-top: 8rem;
    	padding-bottom: 8rem;
		font-size: 1rem;
	}
}

.inter_content{
	h2{
		font-size: 100px;
		color: #fff;
		font-weight: bold;
		position: relative;
		font-family: $hee;
		margin-bottom: 30px;
		&:before{
			content: "";
			height: 4px;
			width: 100px;
			background: $baseColor;
			position: absolute;
			top: -12px;
			left: 5px;
		}
	}
	p{
		color: #fff;
		font-family: $hee;
		font-weight: normal;
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 80px;
	}
	a{
		color: #fff;
		font-size: 18px;
		font-family: $hee;
		font-weight: bold;
		text-transform: uppercase;
		@include transition;
		i{
			padding-left: 3px;
		}
		&:hover{
			color: $baseColor;
		}
	}
}
.full_10_box{
	position: relative;
	padding-left: 17px;
	margin-top: 5px;
	&:before{
		content: "";
		height: 153px;
		width: 153px;
		border: 5px solid $baseColor;
		display: inline-block;
		position: absolute;
		left: -7px;
		top: 0px;
		z-index: -1;
	}
	&:after{
		content: "";
		background: url(../img/circle-teckture.png) no-repeat scroll center center;
		width: 191px;
		height: 191px;
		position: absolute;
		right: 1px;
		top: -19px;
		z-index: -1;
	}
	h2{
		font-size: 105px;
		font-family: $os;
		font-weight: bold;
		padding-top: 60px;
	}
	h3{
		font-size: 48px;
		font-weight: 900;
		font-family: $hee;
		text-transform: uppercase;
		padding-left: 7px;
	}
	h5{
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 500;
		font-family: $hee;
		padding-left: 8px;
	}
}


.full_footer{
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	padding-left: 50px;
	padding-right: 50px;
	padding-bottom: 40px;
	z-index: 5;
	background: transparent;
	.float-left{
		h5{
			color: #fff;
			font-size: 14px;
			font-family: $hee;
			font-weight: normal;
			a{
				font-weight: bold;
				color: #fff;
			}
		}
	}
	.float-right{
		.list{
			li{
				display: inline-block;
				margin-right: 25px;
				a{
					color: #fff;
					font-family: $hee;
					font-weight: normal;
					font-size: 14px;
					@include transition;
				}
				&:last-child{
					margin-right: 0px;
				}
				&:hover{
					a{
						color: $baseColor;
					}
				}
			}
		}
	}
	&.content-white{
		background: transparent;
		.float-left{
			h5{
				color: #fff;
				a{
					color: #fff;
				}
			}
		}
		.float-right{
			.list{
				li{
					a{
						color: #fff;
					}
					&:hover{
						a{
							color: $baseColor;
						}
					}
				}
			}
		}
	}
}


.full_testimonials_area{
	.main_title{
		margin-bottom: 80px;
	}
	.full_white{
		.testimonials_slider{
			.owl-prev, .owl-next{
				&:hover{
					color: $dip;
				}
			}
		}
	}
}

.full_spec_area{
	.main_title{
		margin-bottom: 80px;
	}
	.spec_item{
		h4{
			color: #fff;
		}
		p{
			color: $pfont;	
		}
		a{
			&:hover{
				color: #fff;
			}
		}
	}
}



#full_page_slider{
	.left_ar, .right_ar{ 
		height: 70px;
		width: 70px;
		border: 2px solid #fff;
		text-align: center !important;
		line-height: 48px !important;
		color: #fff;
		display: inline-block;
		font-size: 36px !important;
		transition: all 300ms ease !important;
		&:hover{
			background: $dip;
			color: #fff !important;
			border-color: $dip;
		}
	}	
	.left_ar{}	
	ul{
		li{
			.count{
				font-weight: bold !important;
				font-family: $os;
			}
			.left_ar{
				right: 135px !important;
			}
		}
	}
}
@media(min-width: 1800px){
	.full_slider_inner{
		position: relative;
		overflow: hidden;
		height: calc(100vh - 80px);
		.forcefullwidth_wrapper_tp_banner{
			height: calc(100vh - 80px) !important;
			.rev_slider_wrapper{
				height: calc(100vh - 80px) !important;
			}
		}
	}
}

@media(max-height: 900px){
	.full_slider_inner{
		position: relative;
		overflow: hidden;
		height: 100%;
		.forcefullwidth_wrapper_tp_banner{
			height: 100% !important;
			.rev_slider_wrapper{
				height: 100% !important;
			}
		}
	}
}


.left_contact_details{
	h1{
		font-size: 60px;
		font-family: $hee;
		font-weight: bold;
		margin-bottom: 80px;
	}
	h2{
		font-size: 30px;
		color: #666666;
		font-family: $hee;
		font-weight: normal;
		margin-bottom: 12px;
		span{
			color: #fff;
			font-weight: 700;
		}
	}
	p{
		font-size: 14px;
		color: #fff;
		font-family: $hee;
		line-height: 24px;
		margin-bottom: 10px;
	}
	a{
		display: block;
		font-size: 14px;
		color: #fff;
		font-family: $hee;
		line-height: 24px;
		margin-bottom: 20px;
	}
	.map_btn{
		margin-bottom: 0px;
		margin-top: 110px;
		color: #00ccff;
		display: inline-block;
		i{
			padding-left: 2px;
		}
		&:hover{
			color: $baseColor;
		}
	}
}


.right_contact_form{
	h4{
		color: #fff;
		font-weight: bold;
		font-size: 18px;
		font-family: $hee;
		margin-bottom: 80px;
		margin-top: 30px;
	}
	.contact_us_form{
		.form-group{
			margin-bottom: 20px;
			input{
				border-radius: 0px;
				background: transparent;
				border: none;
				border-bottom: 2px solid #525252;
				padding: 0px;
				height: 53px;
				color: $pfont;
				font-family: $hee;
				font-size: 14px;
				outline: none;
				box-shadow: none;
				@include placeholder{
					color: $pfont;
					font-family: $hee;
					font-size: 14px;
				}
				&:focus{
					border-color: #7d7d7d;
					color: #fff;
					@include placeholder{
						color: #fff;
					}
				}
			}
			textarea{
				border-radius: 0px;
				background: transparent;
				border: none;
				border-bottom: 2px solid #525252;
				padding: 5px 0px;
				height: 160px;
				color: $pfont;
				font-family: $hee;
				font-size: 14px;
				resize: none;
				outline: none;
				box-shadow: none;
				@include placeholder{
					color: $pfont;
					font-family: $hee;
					font-size: 14px;
				}
				&:focus{
					border-color: #7d7d7d;
					color: #fff;
					@include placeholder{
						color: #fff;
					}
				}
			}
			.order_s_btn{
				background: transparent;
				border-radius: 0px;
				text-align: left;
				text-transform: uppercase;
				padding: 0px;
				color: #fff;
				font-weight: bold;
				@include transition;
				outline: none !important;
				box-shadow: none !important;
				display: inline-block;
				width: auto;
				&:focus{
					outline: none;
					border: none;
				}
				i{
					padding-left: 5px;
				}
				&:hover{
					color: $baseColor;
				}
			}
			&:last-child{
				margin-bottom: 0px;
				margin-top: 55px;
			}
		}
	}
}
.right_position{
	position: absolute;
	right: 90px;
	z-index: 35;
	ul{
		position: relative;
		top: -6px;
		li{
			margin-bottom: 25px !important;
			a{
				span{
					height: 13px !important;
					width: 13px !important;
					background: #fff !important;
					opacity: .25;
					border: none !important;
					transition: all 300ms ease;
				}
				&.active{
					span{
						opacity: 1;
					}
				}
			}
			&:last-child{
				margin-bottom: 0px !important;
			}
		}
	}
	&.white{
		ul{
			li{
				a{
					span{
						background: #fff !important;
						opacity: .5;
						transition: all 300ms ease;
					}
					&.active{
						span{
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

.section{
	.box_content{
		transform: translateY(7rem);
		transition: all .35s .6s ease;
		opacity: 0;
		.main_title{
			h2{
				text-transform: capitalize;
				font-size: 60px;
			}
		}
	}
	&.active{
		.box_content{
			transform: translateY(0rem);
			opacity: 1;
		}
	}
}





/* Contact Success and error Area css
============================================================================================ */
.modal-message{
    .modal-dialog{
        position: absolute;
        top: 36%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) !important; 
        margin: 0px;
        max-width: 500px;
        width: 100%;
        .modal-content{
            .modal-header{
                text-align: center;
                display: block;
                border-bottom: none;
                padding-top: 50px;
                padding-bottom: 50px;
                .close{
                    position: absolute;
                    right: -15px;
                    top: -15px;
                    padding: 0px;
                    color: #fff;
                    opacity: 1;
                    cursor: pointer;
					outline: none !important;
					box-shadow: none !important;
                }
                h2{
                    display: block;
                    text-align: center;
                    color: $baseColor;
                    padding-bottom: 10px;
					font-family: $hee;
                }
                p{
                    display: block;
                }
            }
        }
    }
}