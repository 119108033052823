/* Border Button css
============================================================================================ */
.br_btn{
	display: inline-block;
	color: #fff;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: bold;
	font-family: $hee;
	position: relative;
	line-height: 40px;
	margin-bottom: 0px;
	@include transition;
	&:before{
		content: "";
		width: 100%;
		height: 2px;
		background: #fff;
		position: absolute;
		left: 0px;
		bottom: 0px;
		@include transition;
	}
	&:hover{
		color: $baseColor;
		&:before{
			background: $baseColor;
		}
	}
	&.white{
		color: $dip;
		&:before{
			background: $dip;
		}
		&:hover{
			color: $baseColor;
			&:before{
				background: $baseColor;
			}
		}
	}
}

.view_btn{
	color: $baseColor;
	font-family: $hee;
	font-size: 14px;
	font-weight: 500;
	@include transition;
	&:hover{
		color: #fff;
	}
	&.white{
		&:hover{
			color: #fff;
		}
	}
}

.see_btn{
	font-size: 12px;
	color: $pfont;
	font-weight: bold;
	font-family: $hee;
	letter-spacing: .3px;
	text-transform: uppercase;
	margin-top: 25px;
	display: block;
	@include transition;
	&:hover{
		color: $baseColor; 
	}
}


.comment_btn{
	width: 170px;
	height: 50px;
	background: $dip;
	color: #fff;
	border-radius: 0px;
	border: none;
	box-shadow: none !important;
	outline: none !important;
	font-size: 14px;
	font-family: $hee;
	font-weight: bold;
	@include transition;
	letter-spacing: .35px;
	text-transform: uppercase;
	&:hover{
		background: $baseColor;
	}
}

/* Border Button css
============================================================================================ */